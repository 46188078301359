import React, { useEffect, useRef } from 'react';
import { animated, useTransition } from 'react-spring';

import { useApplication } from '../../contexts/Application';

import Landscape from '../../components/Landscape';
import Rings from '../../components/Rings';
import Headline from '../../components/_common/Headline';
import Subtitle from '../../components/_common/Subtitle';
import { useGesture } from 'react-use-gesture';
import { useLandscape } from '../../contexts/Landscape';
import Dots from '../../components/Dots';

const Home = () => {
  const { selectedSearchTerm, setSearchTerm } = useLandscape();
  const { site, isLandscape, setLandscape } = useApplication();
  const { highlightPage, landscapeEntries } = site;
  const isScrolling = useRef(false);

  useEffect(() => {
    !isLandscape 
    ? document.body.classList.add('no-scroll')
    : document.body.classList.remove('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isLandscape]);

  useEffect(() => {
    if (selectedSearchTerm && isLandscape) {
      setSearchTerm(selectedSearchTerm);
    }
  }, [selectedSearchTerm, isLandscape]);

  useGesture({
    onScroll: ({ scrolling }) => {
      isScrolling.current = scrolling;
    },
    onWheel: ({ last, vxvy}) => {
      if (last) return;
        if (vxvy[1] > 0.1 && !isScrolling.current && !isLandscape) {
          setLandscape(true);
        }
        if (vxvy[1] < -0.8 && !isScrolling.current && isLandscape) {
          setLandscape(false);
        }
        return true;
    },
    onDrag: ({ down, movement: [mx, my] }) => {
      if (my > 0 && !isLandscape) setLandscape(true);
      if (my < -40 && isLandscape) setLandscape(false);
    }
  }, { domTarget: window, eventOptions: { passive: false }, useTouch: true });

  const transitions = useTransition(!isLandscape, {
    from: { position: 'absolute', opacity: 0 }, 
    enter: { position: 'relative', opacity: 1 },
    leave: { position: 'absolute', opacity: 0 }
  });

  return ((highlightPage && landscapeEntries) &&
    <>
      <Rings />
      { transitions((props, item) =>   
        item 
          ? (
            <animated.div style={ props } className="highlight-container">
              <Subtitle content={ highlightPage.subtitle } />
              <Headline content={ highlightPage.headline } url={ highlightPage.url } />
              <Dots />
            </animated.div>            
          )
          : (
            <animated.div className="landscape-container" style={ props }>
              <Landscape landscapeEntries={ landscapeEntries } />
            </animated.div>
          )
      )}
    </>
  );
};

export default Home;