import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { createRoot } from 'react-dom/client';

import './assets/styles/style.scss';
import { ApplicationProvider } from './contexts/Application';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
  <React.StrictMode>
    <ApplicationProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApplicationProvider>
  </React.StrictMode>
);