import React, { useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router';

import { animated, useSpring } from 'react-spring';
import { useMenu } from '../../contexts/Navigation';
import { useApplication } from '../../contexts/Application';

import { useLandscape } from '../../contexts/Landscape';

const NavHeader = ({ setOpen }) => {
  return (
    <div className="nav__header">
      <h4>Waar ben je naar op zoek?</h4>
      <div className="nav__close" onClick={ () => setOpen(false) }>
        <i className="icon icon--lg icon-cross"></i>
      </div>
    </div>
  );
};

const NavStatic = ({ entries, setOpen }) => {
  const { setSearchTerm } = useLandscape();

  const clickStatic = (event, title, uri) => {
    setOpen(false);  
    setSearchTerm(title);
  };

  return (
    <div className="nav__static">
      { entries && entries.map( (entry, index) => 
        <NavLink
          key={ index } 
          to={ `/${entry.uri}` }
          onClick={ (event) => clickStatic(event, entry.title, entry.uri) }
        >{ entry.title }<br /></NavLink>
      )}          
    </div>
  ); 
};

const NavSearch = ({ searchterms, setOpen }) => {
  const { setSearchTerm } = useLandscape();
  const { setLandscape } = useApplication();
  const history = useNavigate();

  const clickSearch = (searchterm) => {
    setSearchTerm(searchterm);
    setLandscape(true);
    // Close the menu
    setOpen(false);
    // Navigate to home
    history('/');
  };

  return (
    <div className="nav__search">

      { searchterms && searchterms.map( (searchterm, index) => 
        <button
          key={ index }
          onClick={ () => clickSearch(searchterm) }
        >{ searchterm }</button>
      )}

    </div>
  );  
};

const Navigation = () => {
  const { site } = useApplication();
  const [open, setOpen] = useMenu();
  const ref = useRef();
  
  // Check for 'open', if so scroll the menu back up
  useEffect(() => {
    if (open) {
      ref.current.scrollTo(0,0);
    }
  }, [open]);
  
  const menuAnimation = useSpring({
    transform: open ? `translateY(0%) scale(1)` : `translateY(-110%) scale(0.85)`
  });

  const overlayAnimation = useSpring({
    opacity: open ? 1 : 0,
    pointerEvents: open ? 'all' : 'none'
  });

  return (site && 
    <>
      <animated.div className="nav__overlay" onClick={ () => setOpen(false) } style={ overlayAnimation } />
      <animated.nav className="nav" style={ menuAnimation } ref={ ref }>
        <NavHeader setOpen={ setOpen } />
        <NavStatic entries={ site.menu } setOpen={ setOpen } />
        <NavSearch searchterms={ site.searchterms } setOpen={ setOpen } />
      </animated.nav>
    </>
  );
};

export default Navigation;
