import React from 'react';
import { useNavigate } from 'react-router';
import { useApplication } from '../../contexts/Application';
import { useLandscape } from '../../contexts/Landscape';

const Aside = () => {
  const { setLandscape } = useApplication();
  const { setSearchTerm } = useLandscape();
  const history = useNavigate();

  const onLogoClick = () => {
    setLandscape(true);
    history('/');
    setSearchTerm(null);
  };

  return (
    <aside className="view__aside">
      <div className="branding-logo" onClick={ onLogoClick }>
        <div className="branding-logo__img"></div>
        <div className="branding-logo__circle"></div>
      </div>
    </aside>
  );
};

export default Aside;
