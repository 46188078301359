import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-99256127-1';

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
};

const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

const exportAnalyticsObject = {
  init,
  sendEvent,
  sendPageview
};

export default exportAnalyticsObject;