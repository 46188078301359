import React from 'react';

const File = ( { content } ) => {
  return (
    <p>
      { content.url && <a href={ content.url } title={`${ content.caption }`} target="_blank" rel="noopener noreferrer">{ content.caption }</a> }
    </p>
  );
};

export default File;