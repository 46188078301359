import React, { useContext, useEffect, useState } from 'react';

const initialState = false;
const Context = React.createContext();

export const MenuProvider = ({ children }) => {
  const [open, setOpen] = useState(initialState);
  const value = React.useMemo(() => [open, setOpen], [open]);

  // Toggle no-scroll on body when menu is open
  useEffect(() => {
    open 
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');
  }, [open]);

  return (
    <Context.Provider value={ value }>
      { children }
    </Context.Provider>
  );
};

export const useMenu = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useMenu must be used within a MenuProvider tag');
  }
  
  return contextState; 
};