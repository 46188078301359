/* eslint-disable */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useApplication } from './contexts/Application';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import Cases from "./pages/Cases";
import Case from "./pages/Cases/Case";
import Contact from "./pages/Contact";
import Default from "./pages/Default";
import Events from "./pages/Events";
import Home from "./pages/Home";
import News from "./pages/News";
import Newsitem from "./pages/News/Newsitem";
import Partners from "./pages/Partners";
import Team from "./pages/Team";

const __DEV__ = process.env.NODE_ENV === 'development';

const Router = () => {
  const { site } = useApplication();
  const [routes, setRoutes] = useState([]);

  // Google Analytics hook
  if (!__DEV__) {
    useGoogleAnalytics();
  }
    
  const createRoutes = () => {
    const routes = [
      ...site.children.map(page => ({
        path: `/${page.uri}`,
        template: page.template

      })),
      ...site.children
        .filter(page => page.childTemplate)
        .map(page => ({
          path: `/${page.uri}/:id`,
          template: page.childTemplate
        }))
    ];

    // Redirect `/home` to `/`
   routes.find(({ path }) => path === '/home').path = '/';

    // Catch-all fallback
    // routes.push({ path: '/:pathMatch(.*)*', render: () => <Redirect to="/" /> });
    setRoutes(routes);
  };

  useEffect(() => {
    if (site) createRoutes();
  }, [site]);

  const getTemplate = (templateName) => {
    switch (templateName) {
      case 'cases':
				return <Cases />;
      case 'case':
        return <Case />;
      case 'contact':
        return <Contact />;
      case 'events':
        return <Events />;
      case 'news':
        return <News />;
      case 'newsitem':
        return <Newsitem />;
			case 'partners':
				return <Partners />;
      case 'team':
        return <Team />;
			default:
				return <Default/>;
		}
  }

  return (routes &&
    <Routes>

        <Route path="/" element={ <Home /> } >
         
        </Route>

        { routes.map((route, index) =>
          <Route
            key={ index }
            exact
            path={ route.path }
            render={ route.render }
            element={ getTemplate(route.template) }
          ></Route>
        )}

    </Routes>
  );
};

export default Router;
/* eslint-enable */
