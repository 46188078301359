import React from 'react';

import usePage from '../../hooks/usePage';

import PageHeader from '../../components/_common/Page/PageHeader';
import PageBlocks from '../../components/_common/Page/PageBlocks';
import Partner from './Partner';

const Partners = () => {
  const { page } = usePage();
  return (page &&
    <>
      <PageHeader { ...page } />
      <PageBlocks { ...page } />
      <div className="view__cards">

        <div className="card__section">
          { page.partners.map((partner, index) =>
            <Partner
              key={ index }
              { ...partner }
            />
          ) }
        </div>
      </div>
    </>
  );
};

export default Partners;
