import React from 'react';

import usePage from '../../hooks/usePage';

import PageBlocks from '../../components/_common/Page/PageBlocks';
import PageHeader from '../../components/_common/Page/PageHeader';
import TeamMember from './Teammember';

const Team = () => {
  const { page } = usePage();

  const teammembers = page ? page.members.filter(member => !member.isSupport ) : null;
  const supportmembers = page ? page.members.filter(member => member.isSupport ) : null;

  return (page && 
    <>
      <PageHeader { ...page } />
      <PageBlocks { ...page } />
      <div className="view__cards">

        <div className="card__section">
          { teammembers.map((member, index) =>
            <TeamMember
              key={ index }
              { ...member }
            />
          ) }
        </div>

        <div className="contact-block">
          <h3 className="contact-block__name">Supportteam</h3>
          { supportmembers.map((member, index) =>
            <TeamMember
              key={ index }
              { ...member }
            />
          ) }
        </div>        

      </div>
    </>
  );
};

export default Team;
