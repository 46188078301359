import React from 'react';

import usePage from '../../hooks/usePage';

import PageBlocks from '../../components/_common/Page/PageBlocks';
import PageHeader from '../../components/_common/Page/PageHeader';
import CardHeader from '../../components/_common/Cards/CardHeader';

const ContactBlockContact = ({ name, subtitle, mail }) => {
    
  return (
    <div className="contact-block__contact">
      <CardHeader title={ name } subtitle={ subtitle } />
      { mail && <a href={`mailto:${ mail }`}>{ mail }</a> }
    </div>
  );  
};

const ContactBlock = ({ name, contacts }) => {
  return (
    <div className="contact-block">
      <h3 className="contact-block__name">{ name }</h3>
      { contacts.map((contact, index) => 
        <ContactBlockContact 
          key={ index }
          { ...contact }
        />
      )}
    </div>
  );
};

const Contact = () => {
  const { page } = usePage();

  return(page &&
    <>
      <PageHeader headline={ page.title } />
      <PageBlocks { ...page } />
      { page.contactblocks && page.contactblocks.map( (contactblock, index) => 
        <ContactBlock
          key={ index }
          { ...contactblock }
        />
      )}
    </>
  );
};

export default Contact;