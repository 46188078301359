import React from 'react';
import Blocks from './Blocks';

const PageBlocks = ({ blocks }) => {
  return (
    (blocks && blocks.length > 0)
      ? <div className="page__blocks">{ blocks.map(block => Blocks(block)) }</div>
      : null
  );
};

export default PageBlocks;
