import React from 'react';
import classNames from 'classnames';
import CardHeader from '../../../components/_common/Cards/CardHeader';

const Partner = ({ name, description, link }) => {
  const cx = classNames({
    'card': true,
    'card--partner': true
  });

  return (
    <div className={cx}>
      <CardHeader title={ name } link={ link } />

      { description && <div className="card__description" dangerouslySetInnerHTML={{ __html: description }} /> }
    </div>
  );
};

export default Partner;
