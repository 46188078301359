import React from 'react';

import usePage from '../../hooks/usePage';

import PageBlocks from '../../components/_common/Page/PageBlocks';
import PageHeader from '../../components/_common/Page/PageHeader';
import Event from './Event';

const LIMIT_EVENTS = 5;

const Events = () => {
  const { page } = usePage();
  if (!page) return null;

  const { events } = page;
  const currentDate = new Date(),
        upcomingEvents = [], 
        pastEvents = [];
  let displayedEvents = [];

  events.forEach( (event) => {
    new Date(event.startdate) > currentDate 
      ? upcomingEvents.push(event)
      : pastEvents.push(event);
  });

  const pastEventsToAdd = LIMIT_EVENTS - upcomingEvents.length;
  if (pastEventsToAdd > 0) {
    displayedEvents = [...pastEvents.slice( Math.max(0, pastEvents.length - pastEventsToAdd), pastEventsToAdd ), ...upcomingEvents];
  } else {
    displayedEvents = [...upcomingEvents];
  }

  return (page &&
    <>
      <PageHeader headline={ page.title } />
      <PageBlocks { ...page } />
      <div className="view__cards">

        <div className="card__section">
          {
            displayedEvents.map((event, index) =>
              index < LIMIT_EVENTS
                ? <Event
                    key={ index }
                    { ...event }
                  />
                : null
            )
          }        
        </div>
      </div>
    </>
  );
};

export default Events;
