import React from 'react';
import { animated, useSpring } from 'react-spring';
import { useApplication } from '../../contexts/Application';

const Dots = () => {
  const { isLandscape, setLandscape } = useApplication();
  const dots = [1,2,3,4,5];

  const spring = useSpring({
    opacity: !isLandscape ? 1 : 0
  });

  const onClick = () => {
    setLandscape(true);
  };

  return (
    <animated.div className="dots" style={ spring } onClick={ onClick }>
      { dots.map((dot, i) => <div className="dot" key={ i }></div>) }
    </animated.div>
  );
};

export default Dots;