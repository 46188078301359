import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { animated, useSprings, to } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';

import { useLandscape } from '../../contexts/Landscape';
import Link from '../_common/Link';

const trans = (x, y) => `translate3d(${x}px,${y}px,0)`;

const LandscapeEntry = ({ subtitle, title, description, url, searchterms, selected = false }) => {
  const { setSearchTerm } = useLandscape();
  const history = useNavigate();

  const onEntryClick = () => {
    selected 
      ? history( url )
      : setSearchTerm(searchterms[0] || title);
  };

  const cx = classNames({
    'landscape__entry': true,
    'landscape__entry--selected': selected
  });

  return (
    <div className={ cx } onClick={ onEntryClick }>
      <h3 className="landscape__subtitle">{ subtitle }</h3>
      <h3 className="landscape__title">{ title }</h3>
      { selected &&
        <div className="landscape__description">
          <div dangerouslySetInnerHTML={{ __html : description }}></div>
          <Link to={ url }>Lees meer</Link>
        </div>
      }
    </div>
  );
};

const Landscape = ({ landscapeEntries }) => {
  const { selectedSearchTerm } = useLandscape();

  const [hasBoundsForLandscape, setHasBoundsForLandscape] = useState(false);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const [_, setUpdate] = useState(null);
  const gridsRef = useRef([]);
  const selectedRef = useRef([]);

  const parsedEntries = landscapeEntries.map( entry => {
    entry.selected = entry.searchterms.includes(selectedSearchTerm) || selectedSearchTerm === entry.title;
    return entry;
  });

  const springs = useSprings(parsedEntries.length, parsedEntries.map( (entry, index) => ({
    config: { mass: 3 + (Math.random() * 4), tension: 1600 + (Math.random() * 1000), friction: 300 },
    from: { x: 300, y: 300, opacity: 0, position: 'absolute' },
    to: {
      x: gridsRef.current[index] 
        ? gridsRef.current[index].offsetLeft
        : 0,
      y: entry.selected 
        ? selectedRef.current[index] ? selectedRef.current[index].offsetTop : 0
        : gridsRef.current[index] 
          ? gridsRef.current[index].offsetTop
          : 0,
      opacity: selectedSearchTerm 
        ? entry.selected 
          ? 1 
          : 0.25
        : 1
    },
    immediate: false,
    delay: selectedSearchTerm ? 0 : (index * (Math.random() * 100))
  })));  

  const updateGrid = (ref,index) => {
    gridsRef.current[index] = ref;
  };

  const updateSelectedGrid = (ref, index) => {
    selectedRef.current[index] = ref;
  };

  useEffect(() => {
    setUpdate(Math.random());
  }, [selectedSearchTerm]);

  useEffect(() => {
    if (bounds.width && bounds.height && !hasBoundsForLandscape) {
      setHasBoundsForLandscape(true);
    }
  }, [bounds, hasBoundsForLandscape]);

  const cx = classNames({
    'landscape': true,
    'landscape--show-selected': selectedSearchTerm
  });

  return(
    <div className={ cx } ref={ref}>

      <div className="landscape__grid">
        { parsedEntries.map( (entry, index ) => 
          <div 
            key={ index } 
            className="landscape__grid-entry"
            ref={ el => updateGrid(el, index) }
            style={ entry.selected ? { display: 'none' } : {}}
          >
            <LandscapeEntry                
              { ...entry } 
              selected={ false }
            />
          </div>
        )}
        <div className="landscape__grid--selected">
          { parsedEntries.map( (entry, index ) => 
            entry.selected && 
              <div 
                key={ index } 
                className="landscape__grid-entry--selected"
                ref={ el => updateSelectedGrid(el, index) }
              >
                <LandscapeEntry                
                  { ...entry } 
                  selected={ true }
                />
              </div>
          )}          
        </div>
      </div>

      { 
        <div className="landscape__entries">
        { springs.map( ({ x, y, ...rest }, index) =>
          <animated.div
            key={ index }
            style={{
              ...rest, 
              transform: to([x, y], trans)            
            }}
          >
            <LandscapeEntry                
              { ...parsedEntries[index] } 
              selected={ parsedEntries[index].selected }
            />
          </animated.div> 
        )}
        </div>      
      }

    </div>
  );
};

export default Landscape;