import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { useApplication } from '../../contexts/Application';
import { useLocation } from 'react-router';

const Base = ({ children }) => {
  const { isDark, isLandscape, setDark } = useApplication();
  const location = useLocation();
  const firstRender = useMemo(() => {}, []);

  useEffect(() => {
    if (!firstRender) {
      setDark(location.pathname === '/' || location.pathname === '/home');
    }
    // Scroll back to top
    window.scrollTo(0, 0);
  }, [firstRender, location]);

  useEffect(() => {
    // When landscape changes, also set corresponding class to body
    isDark
      ? document.body.classList.add('theme--dark')
      : document.body.classList.remove('theme--dark');
  }, [isDark]);    

  useEffect(() => {
    // When landscape changes, also set corresponding class to body
    isLandscape && isDark
      ? document.body.classList.add('is-landscape')
      : document.body.classList.remove('is-landscape');
  }, [isDark, isLandscape]);    

  const cx = classNames({
    'view': true,
    'theme--dark': isDark,
    'theme--light': !isDark
  });

  return(
    <div className={ cx }>
      { children }
    </div>
  );
};

export default Base;