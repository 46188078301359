import React, { useMemo, useState } from 'react';

import usePage from '../../hooks/usePage';

import PageBlocks from '../../components/_common/Page/PageBlocks';
import PageHeader from '../../components/_common/Page/PageHeader';
import NewsCard from  './NewsCard';
import Pagination from '../../components/_common/Pagination';

const LIMIT_NEWSITEMS = 4;

const News = () => {
  const { page } = usePage();

  const [currentPage, setCurrentPage] = useState(1);
  
  const { news } = page || { news: []};

  const currentNews = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * LIMIT_NEWSITEMS;
    const lastPageIndex = firstPageIndex + LIMIT_NEWSITEMS;
    return news.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, news]);

  if (!page) return null;

  return (page &&
    <>
      <PageHeader headline={ page.title } />
      <PageBlocks { ...page } />
      <div className="view__cards">

        <div className="card__section">
          {
            currentNews.map((newsItem, index) =>
              index < LIMIT_NEWSITEMS
                ? <NewsCard
                    key={ index }
                    { ...newsItem }
                  />
                : null
            )
          }        
        </div>
      </div>
      <Pagination
        className="pagination"
        currentPage={ currentPage }
        totalCount={ news.length }
        pageSize={ LIMIT_NEWSITEMS }
        onPageChange={ page => setCurrentPage(page) }
      />      
    </>
  );
};

export default News;
