import React, { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';

import { useMenu } from '../../contexts/Navigation';
import { useLandscape } from '../../contexts/Landscape';

const Dropdown = () => {
  const [open, setOpen] = useMenu();
  const [hasBounds, setHasBounds] = useState(false);
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });

  const { selectedSearchTerm } = useLandscape();

  const onClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (bounds.width && bounds.height && !hasBounds) {
      setHasBounds(true);
    }
  }, [bounds, hasBounds]);

  return (
    <div className="view__dropdown" ref={ ref }>
      <div className="dropdown" onClick={ onClick }>
        <span>{ selectedSearchTerm || "Waar ben je naar op zoek?" }</span>
        <i className="icon icon-dropdown"></i>
      </div>
    </div>
  );
};

export default Dropdown;
