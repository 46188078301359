import React, { createContext, useContext, useEffect, useReducer } from 'react';
import KirbyRequests from '../../services/Kirby';

const initialState = {
  status: 'INITIALIZING',
  site: null,
  isLandscape: false,
  isDark: true
};

const Context = createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING': {
      return { ...state, status: 'LOADING' };
    }
    case 'SITE_READY': {
      return { ...state, status: 'READY', site: action.payload };
    }
    case 'ERROR': {
      console.warn('Error loading site', action.payload);
      return { ...state, status: 'ERROR' };
    }
    case 'SET_LANDSCAPE': {
      return { ...state, isLandscape: action.payload };
    }
    case 'SET_DARK': {
      return { ...state, isDark: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

// Helper to fetch site data
const fetchSite = async(dispatch) => {
  dispatch({ type: 'LOADING' });
  try {
    const payload = await KirbyRequests.getPage('site');
    dispatch({ type: 'SITE_READY', payload: payload });
  } catch (error) {
    dispatch({ type: 'ERROR' });    
  }
};

// eslint-disable-next-line react/prop-types
export const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLandscape = (value) => {
    dispatch({ type: "SET_LANDSCAPE", payload: value });
  };  

  const setDark = (value) => {
    dispatch({ type: "SET_DARK", payload: value });
  };  

  // If site isn't set yet, fetch the site info
  useEffect(() => {
    fetchSite(dispatch);
  }, []);  

  const value = { 
    ...state,
    setLandscape,
    setDark
  };

  return (
    <Context.Provider value={ value }>
      {children}
     </Context.Provider>
  );
};

export const useApplication = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useApplication must be used within a Provider tag');
  }
  
  return contextState;
};
