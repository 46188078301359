import React from 'react';
import usePage from '../../hooks/usePage';

import PageHeader from '../../components/_common/Page/PageHeader';
import PageBlocks from '../../components/_common/Page/PageBlocks';

const Default = () => {
  const { page } = usePage();

  return (page &&
    <>
      <PageHeader { ...page } />
      <PageBlocks { ...page } />
    </>
  );
};

export default Default;
