import React from 'react';

import Image from './Image';
import Text from './Text';
import Heading from './Heading';
import List from './List';
import Video from './Video';
import File from './File';
import classNames from 'classnames';

const ContentBlocks = {
  image: Image,
  text: Text,
  heading: Heading,
  list: List,
  video: Video,
  file: File
};

const Blocks = (block) => {
  const cx = classNames({
    block: true,
    [`block--${block.type.toLowerCase()}`]: true
  });

  if (typeof ContentBlocks[block.type] !== 'undefined') {
    return (
      <div className={cx} key={ block.id }>
      {
        React.createElement(ContentBlocks[block.type], {
          content: block.content
        })
      }
      </div>
    );
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div style={{ color: 'red' }}>The component | {block.type} | has not been created yet.</div>,
    { key: block._uid }
  );
};

export default Blocks;
