import React, { useContext, useReducer } from 'react';
import { useApplication } from '../Application';

const initialState = {
  selectedSearchTerm: null,
};

const Context = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCHTERM':
      return { 
        ...state, 
        selectedSearchTerm: action.payload
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }  
  }
};

export const LandscapeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Fetch and set initialstate initially
  const { site } = useApplication();

  // Helper function to dispatch, exported in value
  const setSearchTerm = (searchterm) => {
    dispatch({ type: "SET_SEARCHTERM", payload: searchterm });
  };

  const value = { 
    ...state,
    setSearchTerm
  }; 

  return (
    <Context.Provider value={ value }>
      { children }
    </Context.Provider>
  );
};

export const useLandscape = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useLandscape must be used within a LandscapeProvider tag');
  }
  
  return contextState; 
};