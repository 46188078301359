import React from 'react';
import classNames from 'classnames';
import Link from '../../Link';

const CardTitle = ({ content, link }) => {

  const cx = classNames({
    card__title: true
  });

  const component = (
    <h4 className={ cx }>
      { content }
      { link && <i className="icon icon--lg icon-arrow_up" /> }
    </h4>
  );

  if (link) {
    return (
      <Link to={ link }>
        { component }      
      </Link>
    );
  }

  return component;
};

export default CardTitle;