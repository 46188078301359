import React from 'react';
import Headline from '../../Headline';
import Subtitle from '../../Subtitle';

const PageHeader = ({ title, subtitle, headline, children }) => {
  return (
    <div className="page__header">
      { subtitle && <Subtitle content={subtitle} />}
      { headline && <Headline content={headline} />}
      { 
        // If both subtitle and headline aren't set, show the title instead
        (!subtitle && !headline) && <Headline content={ title } /> 
      }
      { children }
    </div>
  );
};

export default PageHeader;
