import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

const isInternalURL = (to) => {
  try {
    const url = new URL(to, window.location.origin);
    return url.hostname === window.location.hostname;
  } catch {
    return false;
  }
};

const Link = ({ to, children, ...rest }) => {
  const isInternal = isInternalURL(to);

  return (
    <>
      { isInternal && <ReactLink to={ to } { ...rest }>{ children }</ReactLink> }
      { !isInternal && <a href={ to } target="_new" { ...rest }>{ children }</a> }
    </>
  );
};

export default Link;