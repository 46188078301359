import React from 'react';
import classNames from 'classnames';

import CardHeader from '../../../components/_common/Cards/CardHeader';

const CaseCard = ({ name, description, link, image }) => {

  const cx = classNames({
    'card': true,
    'card--tile': true
  });

  return (
    <div className={cx}>
      <CardHeader title={ name } link={ link } image={ image } tile={ true } />
      { description && <div className="card__description" dangerouslySetInnerHTML={{ __html: description }} /> }
    </div>
  );
};

export default CaseCard;
