import { useEffect, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import KirbyRequests from '../services/Kirby';

export const usePage = () => {
	const cache = useRef({});

  const location = useLocation();
  const uri = location.pathname.replace(/^\//, '');

	const initialState = {
		status: 'IDLE',
		error: null,
		page: null
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'LOADING':
				return { ...initialState, status: 'LOADING' };
			case 'LOADED':
				return { ...initialState, status: 'LOADED', page: action.payload };
			case 'ERROR':
				return { ...initialState, status: 'ERROR', error: action.payload };
			default:
				return state;
		}
	}, initialState);

	useEffect(() => {
		let cancelRequest = false;
		if (!uri) return;

		const fetchData = async () => {
			dispatch({ type: 'LOADING' });
			if (cache.current[uri]) {
				const data = cache.current[uri];
				dispatch({ type: 'LOADED', payload: data });
			} else {
				try {
          const payload = await KirbyRequests.getPage(uri);
					cache.current[uri] = payload;
					document.title = payload.title + ' | Topsector Creatieve Industrie';
					if (cancelRequest) return;
					dispatch({ type: 'LOADED', payload });
				} catch (error) {
					if (cancelRequest) return;
					dispatch({ type: 'ERROR', payload: error.message });
				}
			}
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
	}, [uri]);

	return state;
};

export default usePage;
