import React from 'react';
import usePage from '../../../hooks/usePage';

import PageHeader from '../../../components/_common/Page/PageHeader';
import PageBlocks from '../../../components/_common/Page/PageBlocks';
import Image from '../../../components/_common/Page/PageBlocks/Blocks/Image';
import Link from '../../../components/_common/Link';

const Newsitem = () => {
  const { page } = usePage();

  return (page &&
    <>
      <PageHeader { ...page }>
        {/* Show the page image by default, as if it were a block */}
        <Image content={ { url: page.primaryImage } } />
      </PageHeader>

      <PageBlocks { ...page } />

      <div className="page__footer">
        <Link to="/news">
          <h5>Bekijk alle nieuwsberichten</h5>
          <i className="icon icon--sm icon-arrow_right" />
        </Link>
      </div>
    </>
  );
};

export default Newsitem;
