import React from 'react';
import classNames from 'classnames';

import CardHeader from '../../../components/_common/Cards/CardHeader';
import CardImage from '../../../components/_common/Cards/CardImage';

const NewsCard = ({ name, publishdate, description, link, image }) => {

  const cx = classNames({
    'card': true,
    'card--news': true
  });

  const date = new Date(publishdate);
  let subtitle = date.toLocaleDateString('nl-NL', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
  });

  return (
    <div className={cx}>
      <CardHeader title={ name } subtitle={ subtitle } link={ link } />
      <CardImage url={ image } multiply={ false } />

      { description && <div className="card__description" dangerouslySetInnerHTML={{ __html: description }} /> }
    </div>
  );
};

export default NewsCard;
