import React from 'react';
import classNames from 'classnames';

import CardHeader from '../../../components/_common/Cards/CardHeader';
import CardImage from '../../../components/_common/Cards/CardImage';

const Event = ({ name, startdate, description, link, avatar }) => {

  const inactive = new Date(startdate) < new Date();

  const cx = classNames({
    'card': true,
    'card--event': true,
    'card--inactive': inactive
  });

  const startDate = new Date(startdate);
  let subtitle = startDate.toLocaleDateString('nl-NL', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
  });

  return (
    <div className={cx}>
      <CardHeader title={ name } subtitle={ subtitle } link={ !inactive ? link : null } />
      <CardImage url={ avatar } multiply={ false } />

      { description && <div className="card__description" dangerouslySetInnerHTML={{ __html: description }} /> }
    </div>
  );
};

export default Event;
