import React from 'react';
import Link from '../../Link';
import CardImage from '../CardImage';
import CardTitle from '../CardTitle';

const CardHeader = ({ title, subtitle, link, image = null, tile = false }) => {

  const component = (
    <div className="card__header">
      <CardTitle content={ title } link={ !tile ? link : null } />
      <h5 className="card__subtitle">{ subtitle }</h5>
      { tile && <CardImage url={ image } /> }
    </div>
  );

  if (tile && link) {
    return (
      <Link to={ link}>
        { component }
      </Link>
    );
  }

  return component;
};

export default CardHeader;
