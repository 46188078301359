import React from 'react';

const Image = ( { content } ) => {
  return (
    <figure>
      { content.url && <img src={ content.url } alt={`${ content.alt }`} /> }
      { content.caption && <figcaption>{ content.caption }</figcaption> }
    </figure>
  );
};

export default Image;