import axios from 'axios';

const __DEV__ = process.env.NODE_ENV === 'development';
const apiURL = __DEV__ ? process.env.REACT_APP_API_URL : '';
const isMultilang = process.env.KIRBY_MULTILANG === 'true';
const languageCode = document.documentElement.lang;
if (__DEV__ && isMultilang) {
  console.log('[useLanguages] Current language code:', languageCode);
}

const pages = new Map();

const fetcher = async url => {
  const response = await axios.get(url);

  if (!response || response.status !== 200) {
    throw new Error(`The requested URL ${url} failed with response error "${response.statusText}".`);
  }

  const contentType = response.headers['content-type'];
  if (!contentType || !contentType.includes('application/json')) {
    throw new TypeError('The response is not a valid JSON response.');
  }
  return response.data;
};

const apiUri = path => {
  let result = apiURL + '';
  if (isMultilang) { result += `/${languageCode}`; };
  result += `/${process.env.REACT_APP_CONTENT_API_SLUG}`;
  result += `/${path}`;
  return result;
};

export const getPage = async (id = 'home', { revalidate = false } = {}) => {
  let page;
  const isCached = pages.has(id);
  const targetUrl = apiUri(`${id}.json`);

  // Use cached page if present in the store, except when revalidating
  if (!revalidate && isCached) {
    if (__DEV__) console.log(`[getPage] Pulling ${id} page data from cache.`);
    return pages.get(id);
  }

  // Otherwise retrieve page data for the first time
  if (__DEV__) console.log(`[getPage] ${revalidate ? `Revalidating ${id} page data.` : `Fetching ${targetUrl}…`}`);

  try {
    page = await fetcher(targetUrl);    
  } catch (error) {
    console.error(error);
  }

  if (!revalidate) {
    if (__DEV__) console.log(`[getPage] Fetched ${id} page data:`, page);
  }

  // Add page data to the store
  if (!isCached || revalidate) {
    pages.set(id, page);
  }

  return page;
};

const hasPage = id => pages.has(id);

const KirbyRequests = ({
  getPage,
  hasPage
});

export default KirbyRequests;
