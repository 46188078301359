import React from 'react';
import classNames from 'classnames';
import CardHeader from '../../../components/_common/Cards/CardHeader';
import CardImage from '../../../components/_common/Cards/CardImage';

const TeamMember = ({ name, subtitle, description, avatar, isSupport }) => {
  const cx = classNames({
    'card': true,
    'card--teammember': !isSupport,
    'card--teammember-compact': isSupport
  });

  return (
    <div className={cx}>
      <CardHeader title={ name } subtitle={ subtitle } />
      <CardImage url={ avatar } />

      { description && <div className="card__description" dangerouslySetInnerHTML={{ __html: description }} /> }
    </div>
  );
};

export default TeamMember;
