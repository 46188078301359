import React from 'react';
//import { BrowserRouter } from 'react-router-dom';

import Router from './router';

import { MenuProvider } from './contexts/Navigation';

import Aside from './components/Aside';
import Base from './components/Base';
import Dropdown from './components/Dropdown';
import Navigation from './components/Navigation';
import { LandscapeProvider } from './contexts/Landscape';
import { useApplication } from './contexts/Application';

const App = () => {
  const { site } = useApplication();
  document.title = 'Topsector Creatieve Industrie';

  return (site && 
    <MenuProvider>
      <LandscapeProvider>

        <Base>
          <Aside />
          <Dropdown />
          
            <Navigation />
            <div className="view__main view--blocks">
              <Router></Router>
            </div>
        </Base>

      </LandscapeProvider>
    </MenuProvider>
  );
};

export default App;
