import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../analytics';

const useGoogleAnalytics = () => {
  let location = useLocation();

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    analytics.sendPageview(currentPath);
  }, [location]);
};

export default useGoogleAnalytics;