import classNames from 'classnames';
import React from 'react';

const CardImage = ({ url, multiply = true }) => {
  const cx = classNames({
    'card__img': true,
    'card__img--non-multiply': !multiply
  });

  return (
    <div className={ cx }>
      <img src={ url } alt=''></img>      
    </div>
  );
};

export default CardImage;