import React from 'react';

const Video = ({ content }) => {
  const { url, caption } = content;

  const createEmbedUrl = (url) => {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://youtube.com/embed/${match[2]}?modestbranding=1&rel=0`;
    } else {
      console.warn('Could not create embed url');
      return null;
    }
  };

  const embedUrl = createEmbedUrl(url);

  return (embedUrl &&
    <div className="block block--video">
      <figure>
        <iframe
          title={`${caption}`}
          width="100%"
          height="auto"
          src={`${embedUrl}`}
          allow="accelerometer; autoplay; clipboard-write; picture-in-picture"
          allowFullScreen
        />
        { caption && <figcaption>{ caption }</figcaption> }
      </figure>
    </div>
  );
};

export default Video;
