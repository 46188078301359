import React, { useMemo, useState } from 'react';

import usePage from '../../hooks/usePage';

import PageBlocks from '../../components/_common/Page/PageBlocks';
import PageHeader from '../../components/_common/Page/PageHeader';
import CaseCard from './CaseCard';
import Pagination from '../../components/_common/Pagination';

const LIMIT_CASES = 4;

const Cases = () => {
  const { page } = usePage();
  const [currentPage, setCurrentPage] = useState(1);
  
  const { cases } = page || { cases: []};

  const currentCases = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * LIMIT_CASES;
    const lastPageIndex = firstPageIndex + LIMIT_CASES;
    return cases.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, cases]);

  if (!page) return null;

  return (page &&
    <>
      <PageHeader headline={ page.title } />
      <PageBlocks { ...page } />
      <div className="view__cards">

        <div className="card__section">
          {
            currentCases.map((caseItem, index) =>
              index < LIMIT_CASES
                ? <CaseCard
                    key={ index }
                    { ...caseItem }
                  />
                : null
            )
          }        
        </div>
      </div>
      <Pagination
        className="pagination"
        currentPage={ currentPage }
        totalCount={ cases.length }
        pageSize={ LIMIT_CASES }
        onPageChange={ page => setCurrentPage(page) }
      />
    </>
  );
};

export default Cases;
