import React from 'react';
import { animated, useSprings, to } from 'react-spring';
import useMeasure from 'react-use-measure';
import { useApplication } from '../../contexts/Application';
import { ResizeObserver } from '@juggle/resize-observer';

const trans = (x, y, scale) => `translate3d(${x}px,${y}px,0) scale(${scale})`;

const Rings = () => {
  const { isLandscape } = useApplication();
  const [ ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const rings = [1, 2, 3, 4];

  const pulse = useSprings(rings.length, rings.map( i => ({
    loop: {
      delay: 0, reset: true
    },
    config: {
      duration: 8000
    },
    from: {
      y: bounds.width < 880 
        ? (bounds.height * .5) + (window.innerWidth * .35) 
        : (bounds.height * .5) + (window.innerWidth * 0.3),
      scale: 1,
      opacity: 0.05,
      pulse: 0
    },
    to: {
      y: bounds.width < 880 
        ? (bounds.height * .5) + (window.innerWidth * .35) 
        : (bounds.height * .5) + (window.innerWidth * 0.3),
      scale: bounds.width < 880 ? 2 : 1.5,
      opacity: 0,
      pulse: 1
    },
    delay: 200 + (i * 2000)
  })));

  const springs = useSprings(rings.length, rings.map( i => ({
    config: { 
      mass: 5,
      tension: 2000,
      friction: 350
    },
    from: { 
      x: 0, 
      y: bounds.width < 880
          ? (bounds.height * 0.5) + (window.innerWidth * 0.3)
          : (bounds.height * 0.9),
      scale: 1, 
      opacity: 0
    },
    to: {
      x: isLandscape 
        ? 20 - (bounds.width * .5)
        : 0,
      y: isLandscape 
        ? bounds.width < 880
          ? 20 - ( bounds.height * .5) 
          : 0
        : bounds.width < 880 
          ? (bounds.height * .5) + (window.innerWidth * .15) 
          : (bounds.height * .5) + (window.innerWidth * 0.3),
      scale: isLandscape 
        ? 3 - (.5 * i) 
        : 1.25,
      opacity: isLandscape ? i * 0.025 : 0
    },
    delay: isLandscape ? 100 : 0
  })));

  return (
    <>
      { (!isLandscape && bounds.width) && 
        <div className="rings rings--pulse" ref={ ref }>
          { pulse.map(({ x = 0, y, scale, pulse, ...rest }, index) => (
            <animated.div key={index} style={{ 
              transform: to([x, y, scale], trans),
              opacity: pulse.to({ range: [0, 0.1, 1], output: [0, 0.1, 0]}),
              ...rest, 
            }} />
          ))}
        </div> 
      }

      <div className="rings" ref={ ref }>
        { springs.map(({ x, y, scale, ...rest }, index) => (
          <animated.div key={index} style={{ 
            ...rest, 
            transform: to([x, y, scale], trans)
          }} />
        ))}
      </div>
    </>
  );
};

export default Rings;