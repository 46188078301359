import React from 'react';
import { useApplication } from '../../../contexts/Application';

const Headline = ({ content, url }) => {
  const { setLandscape } = useApplication();
  
  const onClick = () => {
    setLandscape(true);
  };
  
  return (
    <h1 className="header__title">
      { content } { url && <a href={ url } onClick={ onClick }>Lees meer</a> }
    </h1>
  );
};

export default Headline;
